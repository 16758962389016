import { graphql } from "gatsby"
import React from "react"
import { CampaignPreview } from "../components/CampaignPost/CampaignPost"
import { Layout } from "../components/Layout/Layout"
import { Campaign, GraphqlNodeArray } from "../types"

interface CampaignsProps {
  data: {
    cms: {
      campaigns: GraphqlNodeArray<Campaign>
    }
  }
}

const Campaigns: React.FC<CampaignsProps> = ({ data }) => {
  const handlingSlice = (content: string | null) => {
    if (content === null) {
      return ""
    }
    const conteNT = content.slice(0, 240)
    return conteNT
  }

  return (
    <Layout secondLayout flex bordertop="border-t-2" background="bg-white">
      <div className="flex flex-grow">
        <div className="flex flex-col pt-16 pb-6 lg:w-9/12">
          <div className="flex w-full flex-col items-start px-12 lg:w-1/2 lg:items-center lg:pt-10">
            {data.cms.campaigns.nodes.map(
              ({ fields: { content }, title, slug }, index) => (
                <CampaignPreview
                  key={index}
                  slug={slug}
                  title={title}
                  // html={`${content.slice(0, 240)}...`}
                  html={`${handlingSlice(content)}...`}
                />
              ),
            )}
          </div>
        </div>
        <div className="ml-10 hidden border-r border-gray-200 lg:block" />
      </div>
    </Layout>
  )
}

export default Campaigns

export const pageQuery = graphql`
  {
    cms {
      campaigns(first: 2) {
        nodes {
          slug
          title
          fields {
            content
          }
        }
      }
    }
  }
`
